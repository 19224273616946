import React, {useState} from "react"
import {Button, Col, Container, Row, Modal} from "react-bootstrap"

import Layout from "../components/layout"
import SEO from "../components/seo"
import SignUpModal from "../components/sign-up-modal"
import {graphql} from "gatsby";
import Img from "gatsby-image";
import {FaQuoteLeft, FaQuoteRight, FaStar, FaStarHalfAlt} from "react-icons/fa";
import {FormattedMessage, useIntl} from "gatsby-plugin-intl";
import {HiOutlineBadgeCheck} from "react-icons/hi";

export const query = graphql`
    query Circles($language: String!) {
        circles: file(base: { eq: "baubo-circles-lp.png" }, relativeDirectory: { eq: $language }) {
            childImageSharp {
                fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        ladies: file(relativePath: { eq: "ladies_1_1_call.png" }) {
            childImageSharp {
                fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        mirna: file(relativePath: { eq: "Mirna.jpg" }) {
            childImageSharp {
                fixed(width: 64, height: 64, quality: 100) {
                    ...GatsbyImageSharpFixed
                }
            }
        }
        community: file(relativePath: { eq: "community.png" }) {
            childImageSharp {
                fluid(maxWidth: 128) {
                    ...GatsbyImageSharpFluid,
                    ...GatsbyImageSharpFluidLimitPresentationSize
                }
            }
        }
        books: file(relativePath: { eq: "books_icon.png" }) {
            childImageSharp {
                fluid(maxWidth: 128) {
                    ...GatsbyImageSharpFluid,
                    ...GatsbyImageSharpFluidLimitPresentationSize
                }
            }
        }
        calendar: file(relativePath: { eq: "calendar_icon.png" }) {
            childImageSharp {
                fluid(maxWidth: 128) {
                    ...GatsbyImageSharpFluid,
                    ...GatsbyImageSharpFluidLimitPresentationSize
                }
            }
        }
    }`

const Circles = ({ data, pageContext }) => {
	const [showCalendly, setShowCalendly] = useState(false);
	const [userData, setUserData] = useState(null);
	const [showModal, setShowModal] = useState(false);
	const intl = useIntl();
	const openModal = () => setShowModal(true);
	const closeModal = () => setShowModal(false);
	const handleSuccess = (data) => {
		setUserData(data);
		closeModal();
		setShowCalendly(true);
		return Promise.resolve();
	}
	return (
		<Layout hideSignUp>
			<SEO
				title={intl.formatMessage({ id: "circles.title", defaultMessage: "Join our Circles community" })}
				description={intl.formatMessage({ id: "circles.description", defaultMessage: "Menopause on your terms."})}
			/>
			<section className="bg-light py-5 mb-5">
				<Container className="py-md-5">
					<Row className="align-items-center">
						<Col xs="12" md="6" className="order-1 order-md-0">
							<h1>
								<FormattedMessage id="circles.h1" defaultMessage="Look on the bright side of menopause" />
							</h1>
							<div className="mb-2" dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "circles.p"}) }}/>
							<div className="d-flex align-items-center">
								<Button onClick={openModal} variant="primary" size="lg" className="mr-3"><FormattedMessage id="circles.join-button" defaultMessage="Join a Baushe Circle" /></Button>
							</div>
						</Col>
						<Col xs="12" md="6" className="d-flex justify-content-center mb-4 mb-md-0 order-0 order-md-1">
							<Img className="w-100" fluid={data?.circles?.childImageSharp?.fluid}/>
						</Col>
					</Row>
				</Container>
			</section>
			<section>
				<Container>
					<h1 className="text-center pb-4"><FormattedMessage id="circles.why-join" defaultMessage="Why join a Circle?" /></h1>
					<Row className="align-items-stretch">
						<Col xs="12" md="6" lg="8" className="mb-4">
							<div className="d-flex justify-content-start align-items-center mb-3">
								<Img className="rounded-circle bg-primary mr-3" style={{ minWidth: '64px' }} fixed={data?.mirna?.childImageSharp?.fixed}/>
								<p className="mb-0">
									<b><FormattedMessage id="circles.mirna.name" defaultMessage="Mirna Sevilla" /></b> <br/>
									<span className="text-muted"><FormattedMessage id="circles.mirna.desc" defaultMessage="Baushe Circle member from the Netherlands" /></span>
								</p>
							</div>
							<div className="bg-light rounded shadow-sm p-5 position-relative mb-3">
								<FaQuoteRight className="position-absolute display-4 text-primary" style={{ right: '-0.75rem', bottom: '-1rem'}} />
								<p className="mb-0">
									<FormattedMessage id="circles.mirna.story" defaultMessage="A couple of years ago, I started having severe chronic migraine, lack of concentration, brain fog, and I just didn't feel myself anymore. Little I knew that these symptoms were related to the menopausal transition until I came across Baushe. Through the Baushe Circles, so far I gained a better understanding of what is happening to me, I found the way to the proper treatment, and felt heard. I look forward to our monthly meeting where I learn new things and feel supported by fellow women with whom I can openly talk and even laugh at our discomforts." />
								</p>
							</div>
							<div>
								<h4><FormattedMessage id="circles.no-pill.title" defaultMessage="There is no single pill for menopause." /></h4>
								<FormattedMessage id="circles.no-pill.paragraph" defaultMessage="But together in a Baushe Circle you can figure out what are the signs, learn about your journey and how to experience it, on your terms." />
							</div>
						</Col>
						<Col xs="12" md="6" lg="4" className="pb-4">
							<div className="bg-gradient p-3 h-100 d-flex flex-column justify-content-between">
								<div>
									<b>
										<FormattedMessage id="circles.for-you.title" defaultMessage="Baushe Circles are for you if want to:" />
									</b>
									<ul>
										<li><FormattedMessage id="circles.for-you.list.1" defaultMessage="Openly exchange with women like you, in a safe space" /></li>
										<li><FormattedMessage id="circles.for-you.list.2" defaultMessage="Learn from reliable sources what happens during menopause" /></li>
										<li><FormattedMessage id="circles.for-you.list.3" defaultMessage="Let go of the confusion around the changes in your body & mind" /></li>
									</ul>
								</div>
								<div>
									<b><FormattedMessage id="circles.topics.title" defaultMessage="Some of the topics discussed in a Baushe Circle" /></b>
									<ul>
										<li><FormattedMessage id="circles.topics.list.1" defaultMessage="How to recognize where you are in the transition" /> </li>
										<li><FormattedMessage id="circles.topics.list.2" defaultMessage="Identify  your symptoms" /> </li>
										<li><FormattedMessage id="circles.topics.list.3" defaultMessage="Learn about your coping options" /> </li>
										<li><FormattedMessage id="circles.topics.list.4" defaultMessage="And many more..." /> </li>
									</ul>
								</div>
							</div>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="py-5" id="how-it-works">
				<Container>
					<div className="text-center pb-4 h1"><FormattedMessage id="circles.how-it-works.title" defaultMessage="How it works" /></div>
					<Row className="pt-3">
						<Col xs="12" md="4" className="mb-3">
							<div className="d-flex justify-content-center"><Img className="w-100" fluid={data?.community?.childImageSharp?.fluid} /></div>
							<h3 className="text-center mt-3"><FormattedMessage id="circles.how-it-works.cols.1.title" defaultMessage="The right people" /> </h3>
							<div className="mb-2" dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "circles.how-it-works.cols.1.paragraph" })}}/>
						</Col>
						<Col xs="12" md="4" className="mb-3">
							<div className="d-flex justify-content-center"><Img className="w-100" fluid={data?.books?.childImageSharp?.fluid}/></div>
							<h3 className="text-center mt-3"><FormattedMessage id="circles.how-it-works.cols.2.title" defaultMessage="Reliable sources" /> </h3>
							<p>
								<FormattedMessage id="circles.how-it-works.cols.2.paragraph" defaultMessage="At Baushe, we are committed to providing Circles with all the support they need, such as reliable information from leading specialists." />
							</p>
						</Col>
						<Col xs="12" md="4" className="mb-3">
							<div className="d-flex justify-content-center"><Img className="w-100" fluid={data?.calendar?.childImageSharp?.fluid}/></div>
							<h3 className="text-center mt-3"><FormattedMessage id="circles.how-it-works.cols.3.title" defaultMessage="Monthly meetings" /> </h3>
							<p>
								<FormattedMessage id="circles.how-it-works.cols.3.paragraph" defaultMessage="There you will find a structured agenda, resources to learn about new topics concerning your transition and chance to share, learn from each other and drive accountability." />
							</p>
						</Col>
					</Row>
					<Row className="justify-content-center">
						<Col xs="auto">
							<Button size="lg" onClick={openModal}><FormattedMessage id="circles.join-button" defaultMessage="Join a Baushe Circle" /></Button>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="pb-5 py-md-5">
				<Container>
					<h1 className="text-center pb-4"><FormattedMessage id="circles.start-here.title" defaultMessage="Start here" /></h1>
					<Row className="align-items-center">
						<Col xs="12" md="6" className="d-flex justify-content-center mb-3">
							<Img className="w-75" fluid={data?.ladies?.childImageSharp?.fluid}/>
						</Col>
						<Col xs="12" md="6" className="mb-3">
							<h3 className="mb-4"><FormattedMessage id="circles.start-here.try" defaultMessage="You can try a Baushe circle in 2 easy steps:" /></h3>
							<div className="d-flex flex-column">
								<div className="d-flex align-items-center">
									<span className="number-circle mr-3">1</span>
									<b><FormattedMessage id="circles.start-here.steps.1.title" defaultMessage="Sign up for free" /></b>
								</div>
								<div className="d-flex">
									<div className="vertical-dashed-line align-self-stretch" />
									<p className="rounded bg-light shadow-sm p-3 ml-3">
										<FormattedMessage id="circles.start-here.steps.1.desc" defaultMessage="Express your interest by leaving your contact email, so we can get back to you with further instructions." />
									</p>
								</div>
							</div>
							<div className="d-flex flex-column">
								<div className="d-flex align-items-center">
									<span className="number-circle mr-3">2</span>
									<b><FormattedMessage id="circles.start-here.steps.2.title" defaultMessage="Fill in the form" /></b>
								</div>
								<div className="d-flex">
									<div className="vertical-dashed-line align-self-stretch" />
									<p className="rounded bg-light shadow-sm p-3 ml-3 mb-4">
										<FormattedMessage id="circles.start-here.steps.2.desc" defaultMessage="To provide the best Baushe Circle experience, we ask you a few short questions so we can match you with women in a similar journey as yours." />
									</p>
								</div>
							</div>
							<div className="d-flex flex-column">
								<div className="d-flex align-items-center">
									<span className="number-circle mr-3">3</span>
									<b><FormattedMessage id="circles.start-here.steps.3.title" defaultMessage="You're all set" /> <span role="img" aria-label="confetti">🎉</span></b>
								</div>
								<div className="d-flex">
									<div className="vertical-dashed-line align-self-stretch border-right-0" />
									<p className="rounded bg-light shadow-sm p-3 ml-3 mb-4">
										<FormattedMessage id="circles.start-here.steps.3.desc" defaultMessage="Once we find you a matching Circle, you'll be invited to join your first meeting." />
									</p>
								</div>
							</div>
							<div className="text-center">
								<span className="mr-3"><FormattedMessage id="circles.start-here.ready" defaultMessage="Ready?" /></span><Button size="lg" onClick={openModal}><FormattedMessage id="circles.start-here.title" defaultMessage="Start here" /></Button>
							</div>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="pb-5 py-md-5">
				<Container>
					<h1 className="text-center pb-4"><FormattedMessage id="circles.testimonials.title" defaultMessage="Testimonials" /></h1>
					<Row className="justify-content-md-center">
						<Col xs="12" md="6" lg="4" className="mb-3">
							<div className="d-flex flex-column h-100 justify-content-between p-3 rounded shadow">
								<div className="d-flex justify-content-between align-items-center">
									<FaQuoteLeft className="text-primary mb-2"/>
									<div className="text-success pb-2">{Array.from([1, 2, 3, 4, 5]).map((index) => <FaStar key={index} />)}</div>
								</div>
								<p className="text-muted"><FormattedMessage id="circles.testimonials.1" defaultMessage="I didn't know what to expect, but I can say I came out enthusiastic. What a great way to support each other and expand your own knowledge."/> </p>
								<div className="blockquote-footer mb-2 align-self-end">
									Maria, <cite title="Circle Member"><FormattedMessage id="circles.testimonials.member" defaultMessage="Circle Member" /></cite>
								</div>
								<FaQuoteRight className="text-primary align-self-end" />
							</div>
						</Col>
						<Col xs="12" md="6" lg="4" className="mb-3">
							<div className="d-flex flex-column h-100 justify-content-between p-3 rounded shadow">
								<div className="d-flex justify-content-between align-items-center">
									<FaQuoteLeft className="text-primary mb-2"/>
									<div className="text-success pb-2">{Array.from([1, 2, 3, 4, 5]).map((index) => <FaStar key={index} />)}</div>
								</div>
								<p className="text-muted"><FormattedMessage id="circles.testimonials.2" defaultMessage="I definitelly recommend it. How great it is to get support and recognition but also to give it to others."/> </p>
								<div className="blockquote-footer mb-2 align-self-end">
									Leonor, <cite title="Circle Member"><FormattedMessage id="circles.testimonials.member" defaultMessage="Circle Member" /></cite>
								</div>
								<FaQuoteRight className="text-primary align-self-end" />
							</div>
						</Col>
					</Row>
					<Row className="align-items-center justify-content-md-center">
						<Col xs="12" md="6" lg="4" className="mb-3">
							<div className="d-flex flex-column h-100 justify-content-between p-3 rounded shadow">
								<div className="d-flex justify-content-between align-items-center">
									<FaQuoteLeft className="text-primary mb-2"/>
									<div className="text-success pb-2">{Array.from([1, 2, 3, 4, 5]).map((index) => <FaStar key={index} />)}</div>
								</div>
								<p className="text-muted"><FormattedMessage id="circles.testimonials.3" defaultMessage="Baushe Circle saved me hours of googling and helped me to face the reality I've been hiding from. Sharing with my Circle peers makes the menopause journey and feel understood."/> </p>
								<div className="blockquote-footer mb-2 align-self-end">
									Rosita, <cite title="Circle Member"><FormattedMessage id="circles.testimonials.member" defaultMessage="Circle Member" /></cite>
								</div>
								<FaQuoteRight className="text-primary align-self-end" />
							</div>
						</Col>
						<Col xs="12" md="6" lg="4" className="mb-3">
							<div className="d-flex flex-column h-100 justify-content-between p-3 rounded shadow">
								<div className="d-flex justify-content-between align-items-center">
									<FaQuoteLeft className="text-primary mb-2"/>
									<div className="text-success pb-2">{Array.from([1, 2, 3, 4]).map((index) => <FaStar key={index} />)}<FaStarHalfAlt /></div>
								</div>
								<p className="text-muted"><FormattedMessage id="circles.testimonials.4" defaultMessage="Circles is a great initiative to come in open conversation with other women in the transition. Recognition of our experiences is one of the most important helping tools in the menopause."/> </p>
								<div className="blockquote-footer mb-2 align-self-end">
									Victoria, <cite title="Circle Member"><FormattedMessage id="circles.testimonials.member" defaultMessage="Circle Member" /></cite>
								</div>
								<FaQuoteRight className="text-primary align-self-end" />
							</div>
						</Col>
					</Row>
					<Row className="justify-content-center align-items-center mt-3">
						<Col xs="12" md="6" lg="4" className="mb-3">
							<p className="text-center mb-0 text-md-left h5"><FormattedMessage id="circles.testimonials.try-free" defaultMessage="Try it yourself! Baushe Circles are free."/></p>
						</Col>
						<Col xs="12" md="6" lg="4" className="text-center text-md-right mb-3">
							<Button size="lg" onClick={openModal}><FormattedMessage id="circles.testimonials.join-now" defaultMessage="Join now"/></Button>
						</Col>
					</Row>
				</Container>
				<SignUpModal show={showModal} group="circles" onHide={closeModal} redirect={false} onSuccess={handleSuccess} title={intl.formatMessage({ id: "circles.modal.title", defaultMessage: "Sign up to join Baushe Circles" })} />
				<Modal show={showCalendly} onHide={() => setShowCalendly(false)}>
					<Modal.Header closeButton className="border-bottom-0">
						<Modal.Title><FormattedMessage id="circles.modal.step-2" defaultMessage="Step 2"/> </Modal.Title>
					</Modal.Header>
					<Modal.Body className="text-center">
						<HiOutlineBadgeCheck className="display-2 mb-4 text-success" />
						<p>
							<b><FormattedMessage id="circles.modal.thank-you" defaultMessage="Thank you for subscribing to Baushe Circles!" /></b> <br/>
							<FormattedMessage id="circles.modal.message" defaultMessage="As step 2, we would like to ask you a few short questions that will allow us to find the best matching Circle for you."/>
						</p>
						<Button className="my-4" size="lg" as="a" href={intl.locale === 'en' ? 'https://forms.gle/6HUTMCsVNVi4kvmB8' : 'https://forms.gle/eWNqhngXpqfLLzN68'}><FormattedMessage id="circles.intake-form.button" defaultMessage="Go to questionnaire" /></Button>
					</Modal.Body>
				</Modal>
			</section>
		</Layout>
	)
}

export default Circles
